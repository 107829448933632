import { SVGProps } from "react";

export default function AppStoreQRRKV(props: SVGProps<SVGSVGElement>) {
	return (
		<svg
			width="296"
			height="296"
			viewBox="0 0 296 296"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
		>
			<rect x="0" y="0" width="296" height="296" fill="#ffffff" />
			<defs>
				<rect id="p" width="8" height="8" />
			</defs>
			<g fill="#000000">
				<use xlinkHref="#p" x="32" y="32" />
				<use xlinkHref="#p" x="32" y="40" />
				<use xlinkHref="#p" x="32" y="48" />
				<use xlinkHref="#p" x="32" y="56" />
				<use xlinkHref="#p" x="32" y="64" />
				<use xlinkHref="#p" x="32" y="72" />
				<use xlinkHref="#p" x="32" y="80" />
				<use xlinkHref="#p" x="32" y="96" />
				<use xlinkHref="#p" x="32" y="104" />
				<use xlinkHref="#p" x="32" y="120" />
				<use xlinkHref="#p" x="32" y="128" />
				<use xlinkHref="#p" x="32" y="136" />
				<use xlinkHref="#p" x="32" y="152" />
				<use xlinkHref="#p" x="32" y="160" />
				<use xlinkHref="#p" x="32" y="208" />
				<use xlinkHref="#p" x="32" y="216" />
				<use xlinkHref="#p" x="32" y="224" />
				<use xlinkHref="#p" x="32" y="232" />
				<use xlinkHref="#p" x="32" y="240" />
				<use xlinkHref="#p" x="32" y="248" />
				<use xlinkHref="#p" x="32" y="256" />
				<use xlinkHref="#p" x="40" y="32" />
				<use xlinkHref="#p" x="40" y="80" />
				<use xlinkHref="#p" x="40" y="96" />
				<use xlinkHref="#p" x="40" y="112" />
				<use xlinkHref="#p" x="40" y="120" />
				<use xlinkHref="#p" x="40" y="128" />
				<use xlinkHref="#p" x="40" y="144" />
				<use xlinkHref="#p" x="40" y="152" />
				<use xlinkHref="#p" x="40" y="176" />
				<use xlinkHref="#p" x="40" y="184" />
				<use xlinkHref="#p" x="40" y="192" />
				<use xlinkHref="#p" x="40" y="208" />
				<use xlinkHref="#p" x="40" y="256" />
				<use xlinkHref="#p" x="48" y="32" />
				<use xlinkHref="#p" x="48" y="48" />
				<use xlinkHref="#p" x="48" y="56" />
				<use xlinkHref="#p" x="48" y="64" />
				<use xlinkHref="#p" x="48" y="80" />
				<use xlinkHref="#p" x="48" y="96" />
				<use xlinkHref="#p" x="48" y="112" />
				<use xlinkHref="#p" x="48" y="128" />
				<use xlinkHref="#p" x="48" y="136" />
				<use xlinkHref="#p" x="48" y="160" />
				<use xlinkHref="#p" x="48" y="176" />
				<use xlinkHref="#p" x="48" y="208" />
				<use xlinkHref="#p" x="48" y="224" />
				<use xlinkHref="#p" x="48" y="232" />
				<use xlinkHref="#p" x="48" y="240" />
				<use xlinkHref="#p" x="48" y="256" />
				<use xlinkHref="#p" x="56" y="32" />
				<use xlinkHref="#p" x="56" y="48" />
				<use xlinkHref="#p" x="56" y="56" />
				<use xlinkHref="#p" x="56" y="64" />
				<use xlinkHref="#p" x="56" y="80" />
				<use xlinkHref="#p" x="56" y="96" />
				<use xlinkHref="#p" x="56" y="104" />
				<use xlinkHref="#p" x="56" y="136" />
				<use xlinkHref="#p" x="56" y="144" />
				<use xlinkHref="#p" x="56" y="176" />
				<use xlinkHref="#p" x="56" y="208" />
				<use xlinkHref="#p" x="56" y="224" />
				<use xlinkHref="#p" x="56" y="232" />
				<use xlinkHref="#p" x="56" y="240" />
				<use xlinkHref="#p" x="56" y="256" />
				<use xlinkHref="#p" x="64" y="32" />
				<use xlinkHref="#p" x="64" y="48" />
				<use xlinkHref="#p" x="64" y="56" />
				<use xlinkHref="#p" x="64" y="64" />
				<use xlinkHref="#p" x="64" y="80" />
				<use xlinkHref="#p" x="64" y="96" />
				<use xlinkHref="#p" x="64" y="104" />
				<use xlinkHref="#p" x="64" y="112" />
				<use xlinkHref="#p" x="64" y="128" />
				<use xlinkHref="#p" x="64" y="136" />
				<use xlinkHref="#p" x="64" y="152" />
				<use xlinkHref="#p" x="64" y="160" />
				<use xlinkHref="#p" x="64" y="168" />
				<use xlinkHref="#p" x="64" y="192" />
				<use xlinkHref="#p" x="64" y="208" />
				<use xlinkHref="#p" x="64" y="224" />
				<use xlinkHref="#p" x="64" y="232" />
				<use xlinkHref="#p" x="64" y="240" />
				<use xlinkHref="#p" x="64" y="256" />
				<use xlinkHref="#p" x="72" y="32" />
				<use xlinkHref="#p" x="72" y="80" />
				<use xlinkHref="#p" x="72" y="128" />
				<use xlinkHref="#p" x="72" y="144" />
				<use xlinkHref="#p" x="72" y="160" />
				<use xlinkHref="#p" x="72" y="176" />
				<use xlinkHref="#p" x="72" y="208" />
				<use xlinkHref="#p" x="72" y="256" />
				<use xlinkHref="#p" x="80" y="32" />
				<use xlinkHref="#p" x="80" y="40" />
				<use xlinkHref="#p" x="80" y="48" />
				<use xlinkHref="#p" x="80" y="56" />
				<use xlinkHref="#p" x="80" y="64" />
				<use xlinkHref="#p" x="80" y="72" />
				<use xlinkHref="#p" x="80" y="80" />
				<use xlinkHref="#p" x="80" y="96" />
				<use xlinkHref="#p" x="80" y="112" />
				<use xlinkHref="#p" x="80" y="128" />
				<use xlinkHref="#p" x="80" y="144" />
				<use xlinkHref="#p" x="80" y="160" />
				<use xlinkHref="#p" x="80" y="176" />
				<use xlinkHref="#p" x="80" y="192" />
				<use xlinkHref="#p" x="80" y="208" />
				<use xlinkHref="#p" x="80" y="216" />
				<use xlinkHref="#p" x="80" y="224" />
				<use xlinkHref="#p" x="80" y="232" />
				<use xlinkHref="#p" x="80" y="240" />
				<use xlinkHref="#p" x="80" y="248" />
				<use xlinkHref="#p" x="80" y="256" />
				<use xlinkHref="#p" x="88" y="96" />
				<use xlinkHref="#p" x="88" y="120" />
				<use xlinkHref="#p" x="88" y="128" />
				<use xlinkHref="#p" x="88" y="152" />
				<use xlinkHref="#p" x="88" y="160" />
				<use xlinkHref="#p" x="88" y="176" />
				<use xlinkHref="#p" x="88" y="184" />
				<use xlinkHref="#p" x="88" y="192" />
				<use xlinkHref="#p" x="96" y="40" />
				<use xlinkHref="#p" x="96" y="56" />
				<use xlinkHref="#p" x="96" y="72" />
				<use xlinkHref="#p" x="96" y="80" />
				<use xlinkHref="#p" x="96" y="96" />
				<use xlinkHref="#p" x="96" y="160" />
				<use xlinkHref="#p" x="96" y="184" />
				<use xlinkHref="#p" x="96" y="192" />
				<use xlinkHref="#p" x="96" y="200" />
				<use xlinkHref="#p" x="96" y="208" />
				<use xlinkHref="#p" x="96" y="224" />
				<use xlinkHref="#p" x="96" y="232" />
				<use xlinkHref="#p" x="96" y="240" />
				<use xlinkHref="#p" x="96" y="248" />
				<use xlinkHref="#p" x="96" y="256" />
				<use xlinkHref="#p" x="104" y="32" />
				<use xlinkHref="#p" x="104" y="40" />
				<use xlinkHref="#p" x="104" y="48" />
				<use xlinkHref="#p" x="104" y="56" />
				<use xlinkHref="#p" x="104" y="96" />
				<use xlinkHref="#p" x="104" y="104" />
				<use xlinkHref="#p" x="104" y="112" />
				<use xlinkHref="#p" x="104" y="120" />
				<use xlinkHref="#p" x="104" y="128" />
				<use xlinkHref="#p" x="104" y="136" />
				<use xlinkHref="#p" x="104" y="160" />
				<use xlinkHref="#p" x="104" y="184" />
				<use xlinkHref="#p" x="104" y="208" />
				<use xlinkHref="#p" x="104" y="216" />
				<use xlinkHref="#p" x="104" y="232" />
				<use xlinkHref="#p" x="104" y="256" />
				<use xlinkHref="#p" x="112" y="48" />
				<use xlinkHref="#p" x="112" y="64" />
				<use xlinkHref="#p" x="112" y="72" />
				<use xlinkHref="#p" x="112" y="80" />
				<use xlinkHref="#p" x="112" y="96" />
				<use xlinkHref="#p" x="112" y="120" />
				<use xlinkHref="#p" x="112" y="136" />
				<use xlinkHref="#p" x="112" y="144" />
				<use xlinkHref="#p" x="112" y="152" />
				<use xlinkHref="#p" x="112" y="160" />
				<use xlinkHref="#p" x="112" y="168" />
				<use xlinkHref="#p" x="112" y="192" />
				<use xlinkHref="#p" x="112" y="200" />
				<use xlinkHref="#p" x="112" y="208" />
				<use xlinkHref="#p" x="112" y="224" />
				<use xlinkHref="#p" x="112" y="240" />
				<use xlinkHref="#p" x="112" y="248" />
				<use xlinkHref="#p" x="120" y="40" />
				<use xlinkHref="#p" x="120" y="48" />
				<use xlinkHref="#p" x="120" y="56" />
				<use xlinkHref="#p" x="120" y="112" />
				<use xlinkHref="#p" x="120" y="120" />
				<use xlinkHref="#p" x="120" y="128" />
				<use xlinkHref="#p" x="120" y="176" />
				<use xlinkHref="#p" x="120" y="184" />
				<use xlinkHref="#p" x="120" y="192" />
				<use xlinkHref="#p" x="120" y="240" />
				<use xlinkHref="#p" x="120" y="248" />
				<use xlinkHref="#p" x="120" y="256" />
				<use xlinkHref="#p" x="128" y="40" />
				<use xlinkHref="#p" x="128" y="64" />
				<use xlinkHref="#p" x="128" y="80" />
				<use xlinkHref="#p" x="128" y="88" />
				<use xlinkHref="#p" x="128" y="96" />
				<use xlinkHref="#p" x="128" y="112" />
				<use xlinkHref="#p" x="128" y="136" />
				<use xlinkHref="#p" x="128" y="152" />
				<use xlinkHref="#p" x="128" y="160" />
				<use xlinkHref="#p" x="128" y="176" />
				<use xlinkHref="#p" x="128" y="200" />
				<use xlinkHref="#p" x="128" y="216" />
				<use xlinkHref="#p" x="128" y="224" />
				<use xlinkHref="#p" x="128" y="240" />
				<use xlinkHref="#p" x="136" y="32" />
				<use xlinkHref="#p" x="136" y="56" />
				<use xlinkHref="#p" x="136" y="64" />
				<use xlinkHref="#p" x="136" y="96" />
				<use xlinkHref="#p" x="136" y="104" />
				<use xlinkHref="#p" x="136" y="112" />
				<use xlinkHref="#p" x="136" y="128" />
				<use xlinkHref="#p" x="136" y="136" />
				<use xlinkHref="#p" x="136" y="144" />
				<use xlinkHref="#p" x="136" y="160" />
				<use xlinkHref="#p" x="136" y="176" />
				<use xlinkHref="#p" x="136" y="200" />
				<use xlinkHref="#p" x="136" y="208" />
				<use xlinkHref="#p" x="136" y="216" />
				<use xlinkHref="#p" x="144" y="40" />
				<use xlinkHref="#p" x="144" y="56" />
				<use xlinkHref="#p" x="144" y="80" />
				<use xlinkHref="#p" x="144" y="88" />
				<use xlinkHref="#p" x="144" y="96" />
				<use xlinkHref="#p" x="144" y="112" />
				<use xlinkHref="#p" x="144" y="128" />
				<use xlinkHref="#p" x="144" y="152" />
				<use xlinkHref="#p" x="144" y="160" />
				<use xlinkHref="#p" x="144" y="168" />
				<use xlinkHref="#p" x="144" y="184" />
				<use xlinkHref="#p" x="144" y="208" />
				<use xlinkHref="#p" x="144" y="224" />
				<use xlinkHref="#p" x="144" y="248" />
				<use xlinkHref="#p" x="152" y="40" />
				<use xlinkHref="#p" x="152" y="72" />
				<use xlinkHref="#p" x="152" y="88" />
				<use xlinkHref="#p" x="152" y="96" />
				<use xlinkHref="#p" x="152" y="112" />
				<use xlinkHref="#p" x="152" y="128" />
				<use xlinkHref="#p" x="152" y="144" />
				<use xlinkHref="#p" x="152" y="176" />
				<use xlinkHref="#p" x="152" y="184" />
				<use xlinkHref="#p" x="152" y="192" />
				<use xlinkHref="#p" x="152" y="208" />
				<use xlinkHref="#p" x="152" y="224" />
				<use xlinkHref="#p" x="152" y="240" />
				<use xlinkHref="#p" x="160" y="32" />
				<use xlinkHref="#p" x="160" y="40" />
				<use xlinkHref="#p" x="160" y="48" />
				<use xlinkHref="#p" x="160" y="72" />
				<use xlinkHref="#p" x="160" y="80" />
				<use xlinkHref="#p" x="160" y="88" />
				<use xlinkHref="#p" x="160" y="104" />
				<use xlinkHref="#p" x="160" y="128" />
				<use xlinkHref="#p" x="160" y="136" />
				<use xlinkHref="#p" x="160" y="144" />
				<use xlinkHref="#p" x="160" y="152" />
				<use xlinkHref="#p" x="160" y="168" />
				<use xlinkHref="#p" x="160" y="184" />
				<use xlinkHref="#p" x="160" y="192" />
				<use xlinkHref="#p" x="160" y="200" />
				<use xlinkHref="#p" x="160" y="208" />
				<use xlinkHref="#p" x="160" y="224" />
				<use xlinkHref="#p" x="160" y="232" />
				<use xlinkHref="#p" x="160" y="240" />
				<use xlinkHref="#p" x="160" y="248" />
				<use xlinkHref="#p" x="168" y="40" />
				<use xlinkHref="#p" x="168" y="56" />
				<use xlinkHref="#p" x="168" y="64" />
				<use xlinkHref="#p" x="168" y="72" />
				<use xlinkHref="#p" x="168" y="96" />
				<use xlinkHref="#p" x="168" y="104" />
				<use xlinkHref="#p" x="168" y="128" />
				<use xlinkHref="#p" x="168" y="136" />
				<use xlinkHref="#p" x="168" y="144" />
				<use xlinkHref="#p" x="168" y="160" />
				<use xlinkHref="#p" x="168" y="192" />
				<use xlinkHref="#p" x="168" y="224" />
				<use xlinkHref="#p" x="168" y="256" />
				<use xlinkHref="#p" x="176" y="32" />
				<use xlinkHref="#p" x="176" y="40" />
				<use xlinkHref="#p" x="176" y="64" />
				<use xlinkHref="#p" x="176" y="80" />
				<use xlinkHref="#p" x="176" y="104" />
				<use xlinkHref="#p" x="176" y="128" />
				<use xlinkHref="#p" x="176" y="136" />
				<use xlinkHref="#p" x="176" y="168" />
				<use xlinkHref="#p" x="176" y="176" />
				<use xlinkHref="#p" x="176" y="208" />
				<use xlinkHref="#p" x="176" y="216" />
				<use xlinkHref="#p" x="176" y="240" />
				<use xlinkHref="#p" x="176" y="248" />
				<use xlinkHref="#p" x="176" y="256" />
				<use xlinkHref="#p" x="184" y="32" />
				<use xlinkHref="#p" x="184" y="48" />
				<use xlinkHref="#p" x="184" y="56" />
				<use xlinkHref="#p" x="184" y="64" />
				<use xlinkHref="#p" x="184" y="88" />
				<use xlinkHref="#p" x="184" y="104" />
				<use xlinkHref="#p" x="184" y="136" />
				<use xlinkHref="#p" x="184" y="160" />
				<use xlinkHref="#p" x="184" y="168" />
				<use xlinkHref="#p" x="184" y="192" />
				<use xlinkHref="#p" x="184" y="200" />
				<use xlinkHref="#p" x="184" y="208" />
				<use xlinkHref="#p" x="184" y="216" />
				<use xlinkHref="#p" x="184" y="240" />
				<use xlinkHref="#p" x="184" y="248" />
				<use xlinkHref="#p" x="184" y="256" />
				<use xlinkHref="#p" x="192" y="32" />
				<use xlinkHref="#p" x="192" y="48" />
				<use xlinkHref="#p" x="192" y="80" />
				<use xlinkHref="#p" x="192" y="104" />
				<use xlinkHref="#p" x="192" y="112" />
				<use xlinkHref="#p" x="192" y="120" />
				<use xlinkHref="#p" x="192" y="128" />
				<use xlinkHref="#p" x="192" y="136" />
				<use xlinkHref="#p" x="192" y="168" />
				<use xlinkHref="#p" x="192" y="176" />
				<use xlinkHref="#p" x="192" y="192" />
				<use xlinkHref="#p" x="192" y="200" />
				<use xlinkHref="#p" x="192" y="208" />
				<use xlinkHref="#p" x="192" y="216" />
				<use xlinkHref="#p" x="192" y="224" />
				<use xlinkHref="#p" x="192" y="248" />
				<use xlinkHref="#p" x="200" y="96" />
				<use xlinkHref="#p" x="200" y="120" />
				<use xlinkHref="#p" x="200" y="128" />
				<use xlinkHref="#p" x="200" y="136" />
				<use xlinkHref="#p" x="200" y="152" />
				<use xlinkHref="#p" x="200" y="168" />
				<use xlinkHref="#p" x="200" y="176" />
				<use xlinkHref="#p" x="200" y="192" />
				<use xlinkHref="#p" x="200" y="224" />
				<use xlinkHref="#p" x="200" y="240" />
				<use xlinkHref="#p" x="200" y="248" />
				<use xlinkHref="#p" x="208" y="32" />
				<use xlinkHref="#p" x="208" y="40" />
				<use xlinkHref="#p" x="208" y="48" />
				<use xlinkHref="#p" x="208" y="56" />
				<use xlinkHref="#p" x="208" y="64" />
				<use xlinkHref="#p" x="208" y="72" />
				<use xlinkHref="#p" x="208" y="80" />
				<use xlinkHref="#p" x="208" y="104" />
				<use xlinkHref="#p" x="208" y="136" />
				<use xlinkHref="#p" x="208" y="144" />
				<use xlinkHref="#p" x="208" y="152" />
				<use xlinkHref="#p" x="208" y="168" />
				<use xlinkHref="#p" x="208" y="184" />
				<use xlinkHref="#p" x="208" y="192" />
				<use xlinkHref="#p" x="208" y="208" />
				<use xlinkHref="#p" x="208" y="224" />
				<use xlinkHref="#p" x="208" y="240" />
				<use xlinkHref="#p" x="208" y="248" />
				<use xlinkHref="#p" x="216" y="32" />
				<use xlinkHref="#p" x="216" y="80" />
				<use xlinkHref="#p" x="216" y="96" />
				<use xlinkHref="#p" x="216" y="104" />
				<use xlinkHref="#p" x="216" y="112" />
				<use xlinkHref="#p" x="216" y="128" />
				<use xlinkHref="#p" x="216" y="136" />
				<use xlinkHref="#p" x="216" y="144" />
				<use xlinkHref="#p" x="216" y="152" />
				<use xlinkHref="#p" x="216" y="168" />
				<use xlinkHref="#p" x="216" y="176" />
				<use xlinkHref="#p" x="216" y="184" />
				<use xlinkHref="#p" x="216" y="192" />
				<use xlinkHref="#p" x="216" y="224" />
				<use xlinkHref="#p" x="216" y="232" />
				<use xlinkHref="#p" x="216" y="240" />
				<use xlinkHref="#p" x="216" y="248" />
				<use xlinkHref="#p" x="216" y="256" />
				<use xlinkHref="#p" x="224" y="32" />
				<use xlinkHref="#p" x="224" y="48" />
				<use xlinkHref="#p" x="224" y="56" />
				<use xlinkHref="#p" x="224" y="64" />
				<use xlinkHref="#p" x="224" y="80" />
				<use xlinkHref="#p" x="224" y="104" />
				<use xlinkHref="#p" x="224" y="120" />
				<use xlinkHref="#p" x="224" y="136" />
				<use xlinkHref="#p" x="224" y="144" />
				<use xlinkHref="#p" x="224" y="152" />
				<use xlinkHref="#p" x="224" y="168" />
				<use xlinkHref="#p" x="224" y="176" />
				<use xlinkHref="#p" x="224" y="184" />
				<use xlinkHref="#p" x="224" y="192" />
				<use xlinkHref="#p" x="224" y="200" />
				<use xlinkHref="#p" x="224" y="208" />
				<use xlinkHref="#p" x="224" y="216" />
				<use xlinkHref="#p" x="224" y="224" />
				<use xlinkHref="#p" x="224" y="240" />
				<use xlinkHref="#p" x="224" y="256" />
				<use xlinkHref="#p" x="232" y="32" />
				<use xlinkHref="#p" x="232" y="48" />
				<use xlinkHref="#p" x="232" y="56" />
				<use xlinkHref="#p" x="232" y="64" />
				<use xlinkHref="#p" x="232" y="80" />
				<use xlinkHref="#p" x="232" y="96" />
				<use xlinkHref="#p" x="232" y="120" />
				<use xlinkHref="#p" x="232" y="128" />
				<use xlinkHref="#p" x="232" y="144" />
				<use xlinkHref="#p" x="232" y="160" />
				<use xlinkHref="#p" x="232" y="200" />
				<use xlinkHref="#p" x="232" y="208" />
				<use xlinkHref="#p" x="232" y="232" />
				<use xlinkHref="#p" x="232" y="240" />
				<use xlinkHref="#p" x="232" y="248" />
				<use xlinkHref="#p" x="240" y="32" />
				<use xlinkHref="#p" x="240" y="48" />
				<use xlinkHref="#p" x="240" y="56" />
				<use xlinkHref="#p" x="240" y="64" />
				<use xlinkHref="#p" x="240" y="80" />
				<use xlinkHref="#p" x="240" y="128" />
				<use xlinkHref="#p" x="240" y="144" />
				<use xlinkHref="#p" x="240" y="160" />
				<use xlinkHref="#p" x="240" y="168" />
				<use xlinkHref="#p" x="240" y="176" />
				<use xlinkHref="#p" x="240" y="192" />
				<use xlinkHref="#p" x="240" y="200" />
				<use xlinkHref="#p" x="240" y="208" />
				<use xlinkHref="#p" x="240" y="224" />
				<use xlinkHref="#p" x="240" y="232" />
				<use xlinkHref="#p" x="240" y="240" />
				<use xlinkHref="#p" x="240" y="256" />
				<use xlinkHref="#p" x="248" y="32" />
				<use xlinkHref="#p" x="248" y="80" />
				<use xlinkHref="#p" x="248" y="96" />
				<use xlinkHref="#p" x="248" y="120" />
				<use xlinkHref="#p" x="248" y="152" />
				<use xlinkHref="#p" x="248" y="184" />
				<use xlinkHref="#p" x="248" y="192" />
				<use xlinkHref="#p" x="248" y="200" />
				<use xlinkHref="#p" x="248" y="224" />
				<use xlinkHref="#p" x="248" y="232" />
				<use xlinkHref="#p" x="248" y="240" />
				<use xlinkHref="#p" x="248" y="248" />
				<use xlinkHref="#p" x="256" y="32" />
				<use xlinkHref="#p" x="256" y="40" />
				<use xlinkHref="#p" x="256" y="48" />
				<use xlinkHref="#p" x="256" y="56" />
				<use xlinkHref="#p" x="256" y="64" />
				<use xlinkHref="#p" x="256" y="72" />
				<use xlinkHref="#p" x="256" y="80" />
				<use xlinkHref="#p" x="256" y="104" />
				<use xlinkHref="#p" x="256" y="136" />
				<use xlinkHref="#p" x="256" y="168" />
				<use xlinkHref="#p" x="256" y="192" />
				<use xlinkHref="#p" x="256" y="200" />
				<use xlinkHref="#p" x="256" y="224" />
				<use xlinkHref="#p" x="256" y="232" />
			</g>
			<g></g>
		</svg>
	);
}
