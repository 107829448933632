/**
 * Scan Wristband
 *
 * Scan the wristband using the local Lock API and scanner
 * Get the customer and possible reservation from the API
 */
import { useDispatch, useSelector } from "react-redux";
import { get, post } from "../../helpers/api";
import { showSnackbar } from "../../helpers/notification";
import { useEffect, useState } from "react";
import { selectConfig } from "../../features/config/configSlice";
import axios from "axios";
import {
	clearLoadingOverlay,
	setLoadingOverlay,
} from "../../features/application/appSlice";
import Button from "../button/Button";
import ArrowSmall from "../svg/ArrowSmall";

interface IScanWristband {
	endpoint: string;
	callBack: (response: any) => void;
}

export default function ScanWristband({ endpoint, callBack }: IScanWristband) {
	const config = useSelector(selectConfig);
	const dispatch = useDispatch();
	const [failed, setFailed] = useState(false);

	useEffect(() => {
		getTokenFromScanner();
	}, []);

	const getTokenFromScanner = () => {
		setFailed(false);

		/**
		 * Salto cloud
		 */
		if (config.Property?.LockSystem === "salto_cloud" && config.Kiosk?.EncoderNumber !== undefined) {
      get({
        url: "salto/token/get",
        params: {
          encoder: config.Kiosk?.EncoderNumber
        },
      })
        .then((response) => {
          if(response.data.Success === true){
            setTimeout(() => {
              handleSubmit(response.data.Token);
            }, 1000);
          } else {
            showSnackbar(response.data.Message, "error", "top-center")
            setFailed(true)
          }
        });
		} else {

		/**
		 * Vincard or salto
		 */
			// Testing token
			if (config.Property?.LockApiUrl.includes("chapi")) {
				get({
					url: "customer/token/current",
					params: {},
				})
					.then((response) => {
						setTimeout(() => {
							handleSubmit(response.data.Token);
						}, 1000);
					})
					.catch((error) => {
						showSnackbar("Please try again", "error", "top-center");

						post({
							url: "error",
							params: { message: error.message, response: error.response },
						});
						setFailed(true);
					});
			} else {
				axios
					.get(
						`${config.Property?.LockApiUrl}/curToken?Encoder=${config.Kiosk?.EncoderNumber}`,
					)
					.then((response) => {
						handleSubmit(response.data.result.token);
					})
					.catch((error) => {
						showSnackbar("Please try again", "error", "top-center");
						// showSnackbar(error.message, "error", "top-center")
						post({
							url: "error",
							params: { message: error.message, response: error.response },
						});
						setFailed(true);
					});
			}
		}
	};

	/**
	 * Get customer and reservation by token
	 * execute callback
	 */
	const handleSubmit = (token: number) => {
		dispatch(
			setLoadingOverlay({
				show: true,
				message: "Getting your wristband data.",
				seconds: 6,
			}),
		);

		get({
			url: endpoint,
			params: {
				token: token,
			},
		})
			.then((response) => {
				if (response.data && response.data.Success) {
					callBack(response);
				} else {
					showSnackbar(response.data.Message, "error", "top-center");
					setFailed(true);
				}
				dispatch(clearLoadingOverlay());
			})
			.catch((error) => {
				dispatch(clearLoadingOverlay());
				showSnackbar(error.response?.data.message, "error", "top-center");
				setFailed(true);
			});
	};

	return (
		<>
			{config.Property?.VideoScanWristbandUrl && (
				<video autoPlay muted loop>
					<source
						src={config.Property?.VideoScanWristbandUrl}
						type="video/mp4"
					/>
				</video>
			)}
			{failed && (
				<Button
					type="button"
					onClick={() => getTokenFromScanner()}
					text="Please try again"
					suffix={<ArrowSmall />}
				/>
			)}
		</>
	);
}
